@media only screen and (max-width:500px) {
    .xs\:text-2xl{
        font-size: 18px !important;
    }
}

@media only screen and (min-width:1400px) {
    .container{
        max-width: 1200px !important;
    }
}

body{
    background-color: #0f0f12;
}