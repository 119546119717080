/* ##### 3.0 Wellcome Area CSS ##### */
.HomeDemo1 .welcome_area {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 700px !important;
  overflow: hidden;
}

.HomeDemo1 .Home1WelcomeAreaIMG {
  min-height: 100%;
  background: url(../../../assets/img/bg-img/bg-2.PNG) no-repeat center center;
  background-size: cover;
}

.HomeDemo1 .hero-content {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 10;
}

.dream-btn:hover {
  background-position: right center;
  color: #fff;
}

.HomeDemo1 .hero-content.globe {
  background: linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.75)
  );
}
.HomeDemo1 .hero-content.transparent {
  background: transparent;
}

.HomeDemo1 .welcome_area.ico:after {
  content: "";
  position: absolute;
  height: 318px;
  width: 100%;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  background-image: linear-gradient(180deg, rgba(15, 15, 18, 0), #0f0f12);
}
.HomeDemo1 .hero-content .ico-counter {
  margin: 100px 7% 0;
}
@media (max-width: 992px) {
  .hero-content .ico-counter,
  .HomeDemo1 .hero-content .service-img-wrapper .image-box {
    margin-top: 0px !important;
  }
}
.HomeDemo1 .hero-content .service-img-wrapper .image-box .rings {
  position: absolute;
  top: 50%;
  z-index: -1;
  margin: 0 auto;
  left: 50% !important;
  width: 120%;
  transform: translate(-50%, -50%);
  animation: unset;
  -webkit-animation: unset;
}
.HomeDemo1 .welcome-content {
  position: relative;
  z-index: 1;
  margin-top: 90px;
}

.HomeDemo1 .promo-section {
  margin-bottom: 30px;
}
.HomeDemo1 .integration-link {
  padding: 8px 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  letter-spacing: 0.58px;
  font-size: 14px;
  line-height: 24px;
  color: #d5d5ea;
}
.HomeDemo1 .integration-link:hover {
  color: #fff;
  text-decoration: none;
}
.HomeDemo1 .integration-link:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to bottom right, #766ee6 0, #3540b0);
  border-radius: 100px;
  opacity: 0.5;
}
.HomeDemo1 .integration-icon {
  margin: 0 5px 0 0;
}
.HomeDemo1 .integration-text {
  margin: 0 7px 0 0;
}
.HomeDemo1 .integration-link > * {
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: top;
}

.HomeDemo1 .main-ilustration {
  position: relative;
  background: no-repeat center 65%;
  background-size: contain;
}
.HomeDemo1 .main-ilustration-2 {
  position: relative;
  background: url(../../../assets/img/core-img/robot-2.png) no-repeat center
    bottom;
  height: 100vh;
  bottom: 0;
  background-size: contain;
}
.HomeDemo1 .main-ilustration-3 {
  position: relative;
  background: url(../../../assets/img/core-img/robot-2.png) no-repeat center
    bottom;
  height: 100vh;
  bottom: 0;
  background-size: cover;
}
.HomeDemo1 .main-ilustration-4 {
  position: relative;
  background: url(../../../assets/img/svg/head-bg-1.svg) no-repeat center 65%;
  height: 100vh;
  background-size: cover;
}
@media (max-width: 1200px) {
  .HomeDemo1 .main-ilustration-3 {
    background-size: contain;
    background-position: left;
  }
  .HomeDemo1 .main-ilustration-3 .inovation {
    right: 0% !important;
    top: 5%;
    display: inline-table;
  }
  .HomeDemo1 .main-ilustration-3 .inovation.num2 {
    right: 5%;
    top: 27%;
  }
}
@media (max-width: 767px) {
  .HomeDemo1 .main-ilustration-3 {
    background-size: contain;
    background-position: left;
  }
  .HomeDemo1 .main-ilustration-3 .inovation {
    right: 0% !important;
    top: 42%;
    display: inline-table;
  }
  .HomeDemo1 .main-ilustration-3 .inovation.num2 {
    right: 5%;
    top: auto;
  }
  .HomeDemo1 .welcome-small-wrapper {
    position: absolute;
    width: 38%;
  }
  .HomeDemo1 .welcome-content-small {
    position: absolute;
    top: -30px;
  }
  .HomeDemo1 .welcome-content.ill {
    margin-top: 120px !important;
  }
  .HomeDemo1 .welcome-content h1 {
    font-size: 28px !important;
  }
  .HomeDemo1 .welcome-content {
    padding-top: 50px;
  }
}
@media (max-width: 480px) {
  .HomeDemo1 .welcome-content {
    vertical-align: middle;
    margin-top: 100px !important;
    padding-top: 50px;
  }
  .HomeDemo1 .main-ilustration-3 {
    display: none;
  }
  .HomeDemo1 .welcome-small-wrapper {
    display: none;
  }
  .HomeDemo1 .welcome-content h1 {
    margin-top: 30px !important;
    font-size: 22px !important;
    padding: 0 20px !important;
  }
  .HomeDemo1 .welcome-content h4 {
    font-size: 14px !important;
    line-height: 1.1;
    padding: 0 20px !important;
  }
  .HomeDemo1 .welcome-content p {
    font-size: 11px !important;
    line-height: 1.5 !important;
    padding: 0 20px !important;
  }
}
.HomeDemo1 .main-ilustration-2:before {
  content: "";
  position: absolute;
  width: 30%;
  height: 1000px;
  left: 50%;
  top: -30%;
  transform: rotate(21deg);
  background: rgba(0, 0, 128, 0.7);
}
.HomeDemo1 .main-ilustration-3 .inovation {
  position: absolute;
  bottom: 10%;
  right: -15%;
  width: 40%;
  background: #0d003b;
  padding: 20px;
}
.HomeDemo1 .inovation.num2 {
  right: -57%;
}
.HomeDemo1 .inovation h5 {
  padding-left: 25px;
  position: relative;
}
.HomeDemo1 .inovation h5:before {
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  width: 15px;
  height: 2px;
  background: #25cbd3;
}
.HomeDemo1 .inovation p {
  font-size: 12px;
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .HomeDemo1 .main-ilustration {
    min-width: 650px;
  }
}

.HomeDemo1 .welcome-content h2 {
  font-size: 52px;
  font-weight: 500;
  margin-bottom: 20px;
}
.HomeDemo1 .welcome-content h1 {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #fff;
}
.HomeDemo1 .welcome-content h1.artficial {
  font-size: 38px;
}
.HomeDemo1 .welcome-content p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 30px;
}

.HomeDemo1 .who-we-contant h4 {
  color: #fff;
}

.HomeDemo1 p.artficial {
  padding-left: 20px;
  border-left: 1px solid #fff;
  position: relative;
}
.HomeDemo1 p.artficial:before {
  content: "";
  position: absolute;
  left: -5px;
  top: -8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.HomeDemo1 .welcome-content-small {
  margin-bottom: 50px;
}
.HomeDemo1 .welcome-content-small h4 {
  margin-bottom: 20px;
}
.HomeDemo1 .welcome-content-small p.artficial {
  font-size: 12px;
}
.HomeDemo1 .welcome-video-area {
  position: relative;
  z-index: 1;
  margin-top: 90px;
}

.HomeDemo1 .welcome-video-area .welcome-thumb {
  position: relative;
  z-index: 1;
  margin-top: 50px;
  background: url(../../../assets/img/svg/gradient.svg) no-repeat;
  border-radius: 15px;
  padding: 0px 50px 0;
}

.HomeDemo1 .welcome-video-area .welcome-thumb img {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  position: relative;
  top: -50px;
}

.HomeDemo1 .video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 40px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  color: #4834d4;
}

.HomeDemo1 .video-btn {
  background-color: #4834d4;
  width: 70px;
  height: 70px;
  color: #fff;
  border-radius: 50%;
  line-height: 70px;
  padding: 0;
  text-align: center;
  min-width: 70px;
  font-size: 24px;
}

.HomeDemo1 .video-btn:hover {
  background-color: #4834d4;
  color: #fff;
}

.HomeDemo1 .fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.HomeDemo1 .fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  .HomeDemo1 .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .HomeDemo1 .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}
.HomeDemo1 .welcome_area.video .desc {
  max-width: 60%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .HomeDemo1 .fullscreen-bg {
    // background: url('video/video-frame.png') center center / cover no-repeat;
  }
  .HomeDemo1 .welcome_area.video .desc {
    max-width: 80%;
    margin: 0 auto;
  }
  .HomeDemo1 .fullscreen-bg__video {
    display: none;
  }
}
.HomeDemo1 .video-bg-illution {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #010111;
  z-index: 50;
  opacity: 0.7;
}
