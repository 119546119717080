/* ##### trust Area CSS ##### */
.HomeDemo1 .trust-section {
  position: relative;
  padding: 100px 0 60px;
}
.HomeDemo1 .trust-item {
  background-color: #c4043c;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 55px 20px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 25px;
  box-shadow: 4px 4px 10px 0 rgb(168 67 253 / 30%);
  overflow: hidden;
  border-bottom: 3px solid #a843fd;
}
.HomeDemo1 .ico-platform-logo {
  margin-bottom: 25px;
  min-height: 75px;
}
.HomeDemo1 .check {
  height: 40px;
  margin: 0 -10px;
  background-color: rgba(13, 0, 59, 0.9);
  border-radius: 5px;
  color: #25cbd3;
  position: relative;
}
.HomeDemo1 .check .value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: 600;
  text-shadow: 0 0 5px rgba(0, 243, 255, 0.5);
}
.HomeDemo1 .check .check-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../../../assets/img/svg/checkmark.svg) 50% no-repeat;
  background-size: contain;
  width: 31px;
  height: 23px;
}

.HomeDemo1 .token-distribution {
  padding-bottom: 100px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .HomeDemo1 .token-info-wapper {
    margin-top: 30px;
  }
  .HomeDemo1 .trust-item {
    border-radius: 10px;
    margin-bottom: 0px;
    padding: 30px 10px;
    min-height: 120px;
    padding-bottom: 25px;
    > .ico-platform-logo {
        padding: 0;
        margin: 0;
      > h3 {
        font-size: 20px !important;
        margin: 0;
      }
    }
    > div {
      font-size: 12px !important;
    }
  }
}
.HomeDemo1 .token-info {
  padding: 0 12px;
  width: 100%;
  margin-bottom: 20px;
  float: left;
  display: -ms-flexbox;
  display: flex;
}
.HomeDemo1 .token-info .info-wrapper {
  border: 1px solid #4628ff;
  border-radius: 0px;
  padding: 25px 36px;
  padding-left: 65px;
  background-color: #1b1a28;
  width: 100%;
  position: relative;
  -ms-flex: 1;
  flex: 1;
  display: -ms-flexbox;
  display: flex;
}
.HomeDemo1 .info-wrapper .token-icon {
  left: 20px;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
.HomeDemo1 .info-wrapper .token-descr {
  display: block;
  font-size: 16px;
  color: #fff;
  padding-left: 10px;
  font-weight: 300;
  line-height: 1.25;
}
